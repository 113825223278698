import { ajvResolver } from "@hookform/resolvers/ajv";
import {
  Button,
  Container,
  Fieldset,
  FileInput,
  Group,
  Paper,
  TextInput as TextInputMantine,
} from "@mantine/core";
import { useContext, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { TextInput } from "react-hook-form-mantine";

import { IconFileUpload, IconPlus, IconX } from "@tabler/icons-react";
import { useCreateSponsor } from "../../api/sponsors";
import { AuthContext } from "../../services/context";

import { useUpload } from "../../api/r2";
import { toBase64 } from "../../services/helper";
import { getCurrentWorkspace } from "../../services/userConfig";

function CreateSponsor() {
  const { user } = useContext(AuthContext);

  const [sponsorLogo, setSponsorLogo] = useState(null);

  const currentWorkspace = getCurrentWorkspace(user);

  const {
    register,
    control,
    setValue,
    handleSubmit,
    formState: { dirtyFields },
  } = useForm({
    defaultValues: {
      name: "",
      subtitle: "",
      description: "",
      links: [],
      logo: "",
      workspaceId: currentWorkspace?.id,
      eventId: currentWorkspace?.eventId,
    },
    resolver: ajvResolver({
      type: "object",
      properties: {
        name: { type: "string", minLength: 3 },
        subtitle: { type: "string", minLength: 3 },
        description: { type: "string" },
      },
    }),
  });

  // Calling this custom hook in order to create dynamic form for adding links and labels
  const { fields, append, remove } = useFieldArray({
    name: "links",
    control,
  });

  const { mutate, isPending } = useCreateSponsor();

  const { mutate: upload, isError: uploadIsError } = useUpload();

  const onSubmit = (data) => {
    if (dirtyFields.logo) {
      data.logo = `https://pub-45c81e40f4c74a34bacddebce6171d1d.r2.dev/${sponsorLogo.name}`;
    }
    mutate(data);
  };

  const handleUpload = async () => {
    if (sponsorLogo) {
      upload({
        name: sponsorLogo.name,
        data: await toBase64(sponsorLogo),
      });
      if (uploadIsError) {
        // At this point if logo is required we could prevent saving record to db by making upload sync instead of async. For now if for some reason picture is not saved to R2 in table will be displayed '/'
        console.log(`Picture ${sponsorLogo.name} failed upload to R2`);
      }
    }
  };

  return (
    <Container size={500}>
      <Paper withBorder shadow="md" p={16} mt={16} radius="md">
        <form onSubmit={handleSubmit(onSubmit)}>
          <TextInput
            label="Name"
            placeholder="Name"
            type="text"
            name="name"
            control={control}
            withAsterisk
            mt="md"
          />
          <TextInput
            label="Subtitle"
            placeholder="Gold"
            type="text"
            name="subtitle"
            control={control}
            withAsterisk
            mt="md"
          />
          <TextInput
            label="Description"
            placeholder="Description"
            type="text"
            name="description"
            control={control}
            mt="md"
          />

          <Group>
            <FileInput
              label="Sponsor logo"
              name="sponsorLogo"
              placeholder="Upload file"
              clearable
              rightSection={<IconFileUpload />}
              mt="md"
              accept="image/png,image/jpeg"
              style={{ flexGrow: 1 }}
              onChange={(value) => {
                setSponsorLogo(value);
                setValue("logo", sponsorLogo, { shouldDirty: true });
              }}
            />
          </Group>
          <Fieldset legend="Links" mt="md">
            {fields.map((field, index) => (
              <Fieldset mt="md" key={field.id}>
                <TextInputMantine
                  label="Link label"
                  {...register(`links.${index}.label`)}
                />
                <TextInputMantine
                  label="Link URL"
                  {...register(`links.${index}.url`)}
                />

                <Button
                  mt={5}
                  size="xs"
                  style={{ backgroundColor: "red" }}
                  onClick={() => remove(index)}
                  leftSection={<IconX size={20} />}
                >
                  Remove link
                </Button>
              </Fieldset>
            ))}
            <Button
              mt={5}
              size="xs"
              style={{ justifySelf: "flex-end" }}
              onClick={() => append()}
              leftSection={<IconPlus size={20} />}
            >
              Add link
            </Button>
          </Fieldset>

          <Button
            type="submit"
            mt="md"
            loading={isPending}
            onClick={handleUpload}
          >
            Create sponsor
          </Button>
        </form>
      </Paper>
    </Container>
  );
}

export default CreateSponsor;
