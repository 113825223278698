import { Container, Paper, Title, Button, Center } from "@mantine/core";
import { useForm } from "react-hook-form";
import {
  TextInput,
  Textarea,
  SegmentedControl,
  Select,
} from "react-hook-form-mantine";
import { EvaIcon } from "../EvaIcon";
import { useContext, useEffect } from "react";
import { AuthContext } from "../../services/context";
import { getCurrentWorkspace } from "../../services/userConfig";
import {
  useCreateAnnouncement,
  useGetAllAnnouncements,
} from "../../api/announcements";
import { modals } from "@mantine/modals";
import moreThanOneAnnouncementModal from "./MoreThanOneAnnouncementModal";
import { useGetTags } from "../../api/tag";

const CreateAnnouncement = () => {
  const { user } = useContext(AuthContext);
  const currentWorkspace = getCurrentWorkspace(user);
  const currentEventId = currentWorkspace.eventId;

  const { control, handleSubmit, setValue, reset } = useForm({
    defaultValues: {
      title: "",
      text: "",
      type: "info",
      isVisible: "false",
      eventId: currentEventId,
      targetedTagId: "",
    },
  });

  const { data } = useGetAllAnnouncements({
    eventId: currentEventId,
    getAll: true,
    page: 1,
    limit: 20,
  });

  const { data: tags } = useGetTags({
    eventId: currentEventId,
    type: "people",
  });

  const tagsOptions = [
    { value: "", label: "All users" },
    ...(tags ?? []).map((tag) => ({
      value: String(tag.id),
      label: `${tag.title} - (${tag.usageCount} users)`,
    })),
  ];

  const moreThanOneVisible =
    data?.data.filter((announcement) => announcement.isVisible).length >= 1;

  const { mutate, isPending, isSuccess } = useCreateAnnouncement();

  // Set eventId value to currentEventId If user changes current event, we need
  // to update eventId value in the form.
  useEffect(() => {
    setValue("eventId", currentEventId);
  }, [currentEventId, setValue]);

  // If announcement is created successfully, reset form
  useEffect(() => {
    if (isSuccess) {
      reset();
    }
  }, [isSuccess, reset]);

  const onSubmit = (data) => {
    if (data.isVisible) {
      data.isVisible = data.isVisible === "true";
    }

    // Remove targetedTagId if it is an empty string
    // This is done because the API will not accept an empty string
    // it will translate to 0 and that is not what we want
    // I've tried to use null but it didn't work
    // And refactoring the API repo to accept null as value
    // could break other things, so this is a temporary solution
    if (data.targetedTagId === "") {
      delete data.targetedTagId;
    }

    if (data.isVisible && moreThanOneVisible) {
      const onlyThisCallback = () => {
        mutate(
          { ...data, isOnlyThisVisible: true },
          {
            onSuccess: () => {
              modals.closeAll();
            },
          }
        );
      };

      const showBothCallback = () => {
        mutate(data, {
          onSuccess: () => {
            modals.closeAll();
          },
        });
      };

      moreThanOneAnnouncementModal(showBothCallback, onlyThisCallback);
    } else {
      mutate(data);
    }
  };

  return (
    <Container size={500}>
      <Title order={1}>Create Announcement</Title>
      <Paper withBorder shadow="md" p={16} mt={16} radius="md">
        <form onSubmit={handleSubmit((data) => onSubmit(data))}>
          <TextInput
            label="Title"
            mt="sm"
            name="title"
            control={control}
            placeholder="Your title"
          />
          <Textarea
            label="Text"
            placeholder="Enter description text"
            rows={2}
            mt="sm"
            name="text"
            control={control}
          />
          <Select
            label="Targeted announcement"
            description="Select tag to target users"
            placeholder="Select targeted tag"
            mt="sm"
            name="targetedTagId"
            control={control}
            data={tagsOptions}
            allowDeselect={false}
          />
          <SegmentedControl
            name="type"
            mt="sm"
            control={control}
            data={[
              {
                value: "info",
                label: (
                  <Center style={{ gap: 10 }}>
                    <EvaIcon name="info-outline" />
                    <span>Info</span>
                  </Center>
                ),
              },
              {
                value: "warning",
                label: (
                  <Center style={{ gap: 10 }}>
                    <EvaIcon name="alert-triangle-outline" />
                    <span>Warning</span>
                  </Center>
                ),
              },
            ]}
            fullWidth
          />
          <Select
            label="Visibility"
            placeholder="Select visibility"
            mt="sm"
            name="isVisible"
            control={control}
            data={[
              { value: "true", label: "Visible" },
              { value: "false", label: "Hidden" },
            ]}
          />
          <Button type="submit" mt="sm" loading={isPending}>
            Create announcement
          </Button>
        </form>
      </Paper>
    </Container>
  );
};

export default CreateAnnouncement;
