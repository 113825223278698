import { useState, useContext, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import AttendeesTable from "../components/Attendees/Table";
import { useGetAllAttendees } from "../api/attendees";
import { AuthContext } from "../services/context";
import { getCurrentWorkspace } from "../services/userConfig";
import { modals } from "@mantine/modals";
import SendEmail from "../components/Attendees/SendEmail";
import { useDebouncedValue } from "@mantine/hooks";

function Attendees() {
  const { user } = useContext(AuthContext);
  const currentWorkspace = getCurrentWorkspace(user);
  const currentEventId = currentWorkspace.eventId;

  const [pagination, setPagination] = useState({ page: 1, limit: 50 });
  const [sorting, setSorting] = useState({
    columnAccessor: "firstName",
    direction: "asc",
  });
  const [filter, setFilter] = useState(null);
  const [debouncedFilter] = useDebouncedValue(filter, 200);

  const { data, isPending } = useGetAllAttendees({
    eventId: currentEventId,
    ...pagination,
    sort: sorting,
    filter: debouncedFilter,
  });

  const [searchParams, setSearchParams] = useSearchParams();

  const bulkEmail = searchParams.get("bulkEmail");
  const selectedIdsParam = searchParams.get("selectedIds");

  const openModal = ({ attendeeIds }) => {
    modals.open({
      title: "Send Mail",
      children: (
        <SendEmail
          attendeeIds={attendeeIds}
          eventId={currentEventId}
          numberOfRecipents={selectedIdsParam?.split(",")?.length || 1}
          onSuccess={() => {
            modals.closeAll();
          }}
          onClose={() => {
            modals.closeAll();
          }}
          onCloseModal={() => {
            searchParams.delete("bulkEmail");
            setSearchParams(searchParams);
          }}
        />
      ),
      onClose: () => {
        // Remove the bulkEmail query parameter when the modal is closed
        searchParams.delete("bulkEmail");
        setSearchParams(searchParams);
      },
    });
  };

  const [selectedRecords, setSelectedRecords] = useState([]);

  useEffect(() => {
    if (bulkEmail === "true" && selectedIdsParam) {
      const attendeeIds = selectedIdsParam
        .split(",")
        .map((id) => parseInt(id, 10));
      if (Array.isArray(attendeeIds) && attendeeIds.length > 0) {
        openModal({ attendeeIds });
      }
    }
  }, [searchParams]);

  return (
      <AttendeesTable
        data={data}
        pagination={{ data: pagination, setPagination }}
        sorting={{ data: sorting, setSorting }}
        isPending={isPending}
        actions={{
          openSendEmailModal: ({ attendeeIds }) => {
            openModal({ attendeeIds, eventId: currentEventId });
          },
        }}
        selectedRecords={selectedRecords}
        onSelectedRecordsChange={setSelectedRecords}
        filter={{ data: filter, setFilter }}
      />
  );
}

export default Attendees;
