import { useContext } from "react";
import { DataTable } from "mantine-datatable";
import { Link, useNavigate, useLocation } from "react-router-dom";
import {
  Badge,
  Group,
  ActionIcon,
  Avatar,
  TextInput,
  Select,
  Text,
  Tooltip,
} from "@mantine/core";
import { IconEdit, IconX, IconSearch, IconSend2 } from "@tabler/icons-react";
import AttendeeSwitch from "./AttendeeSwitch";
import { CurrencyInput } from "./CurrencyInput";
import { useGetPeopleTagsBySubtype } from "../../api/tag";
import { AuthContext } from "../../services/context";
import { getCurrentWorkspace } from "../../services/userConfig";

function AttendeesTable({
  data,
  pagination,
  sorting,
  isPending,
  actions,
  selectedRecords,
  onSelectedRecordsChange,
  filter,
}) {
  const navigate = useNavigate();
  const location = useLocation();

  const updateUrlWithSelectedIds = (selectedIds) => {
    const searchParams = new URLSearchParams(location.search);

    if (selectedIds.length > 0) {
      searchParams.set("selectedIds", selectedIds.join(","));
    } else {
      searchParams.delete("selectedIds");
    }

    navigate(`${location.pathname}?${searchParams.toString()}`);
  };

  return (
    <DataTable
      records={data?.data}
      columns={[
        {
          accessor: "picture",
          width: "0%",
          render: (row) => (
            <Group justify="center">
              <Avatar
                src={row?.picture}
                size="md"
                radius="sm"
                alt="avatar"
                color="initials"
                name={`${row?.firstName} ${row?.lastName}`}
              />
            </Group>
          ),
          align: "center",
        },
        {
          accessor: "firstName",
          sortable: true,
          filter: (
            <FilterSearch
              query={filter.data}
              setQuery={filter.setFilter}
              columnName="firstName"
            />
          ),
          filtering: filter?.data?.firstName,
        },
        {
          accessor: "lastName",
          sortable: true,
          filter: (
            <FilterSearch
              query={filter.data}
              setQuery={filter.setFilter}
              columnName="lastName"
            />
          ),
          filtering: filter?.data?.lastName,
        },
        {
          accessor: "email",
          sortable: true,
          filter: (
            <FilterSearch
              query={filter.data}
              setQuery={filter.setFilter}
              columnName="email"
            />
          ),
          filtering: filter?.data?.email,
        },
        {
          accessor: "status",
          render: (row) => (
            <Badge
              color={row.status !== "bounced" ? "blue" : "red"}
              variant="light"
            >
              {row.status}
            </Badge>
          ),
          hidden: true,
        },
        {
          accessor: "category",
          filter: (
            <CategoryFilter query={filter.data} setQuery={filter.setFilter} />
          ),
          filtering: filter?.data?.category,
          render: (row) => {
            const tag = row.tags.find(
              (tag) => tag.subtype === "categoryOfUser"
            );

            if (!tag) return null;

            return (
              <Badge color={tag.color} variant="light">
                {tag.title}
              </Badge>
            );
          },
        },
        {
          accessor: "paid",
          width: "0%",
          render: (row) => (
            <Group justify="center">
              <AttendeeSwitch
                attendeeId={row.id}
                checked={row.paid}
                columnName="paid"
              />
            </Group>
          ),
          sortable: true,
        },
        {
          accessor: "amountPaid",
          width: 250,
          render: (row) => (
            <CurrencyInput
              amountValue={{
                amountPaid: row.amountPaid,
                currency: row.currency,
              }}
              attendeeId={row.id}
            />
          ),
        },
        {
          accessor: "isApproved",
          title: "Approve",
          width: "0%",
          sortable: true,
          render: (row) => (
            <Group justify="center">
              <AttendeeSwitch
                attendeeId={row.id}
                checked={row.isApproved}
                columnName="isApproved"
              />
            </Group>
          ),
        },
        {
          accessor: "actions",
          textAlign: "right",
          width: "0%",
          render: (row) => (
            <Group justify="right">
              <Tooltip label={`Send Email to ${row.firstName} ${row.lastName}`}>
                <ActionIcon
                  size="md"
                  variant="light"
                  color="green"
                  onClick={() =>
                    actions.openSendEmailModal({ attendeeIds: [row.id] })
                  }
                >
                  <IconSend2 />
                </ActionIcon>
              </Tooltip>
              <ActionIcon
                size="md"
                variant="light"
                component={Link}
                to={`${row.id}`}
              >
                <IconEdit />
              </ActionIcon>{" "}
            </Group>
          ),
        },
      ]}
      withTableBorder
      striped
      withColumnBorders
      highlightOnHover
      page={pagination.data.page}
      onPageChange={(page) =>
        pagination.setPagination({ ...pagination.data, page })
      }
      sortStatus={sorting.data}
      onSortStatusChange={sorting.setSorting}
      fetching={isPending}
      recordsPerPage={data?.pagination.perPage}
      totalRecords={data?.pagination.total}
      minHeight={150}
      selectedRecords={selectedRecords}
      onSelectedRecordsChange={(newSelectedRecords) => {
        onSelectedRecordsChange(newSelectedRecords);
        const selectedIds = newSelectedRecords.map((record) => record.id);
        updateUrlWithSelectedIds(selectedIds);
      }}
    />
  );
}

function FilterSearch({ query, setQuery, columnName }) {
  return (
    <TextInput
      label="Attendees"
      description="Show attendees whose names include the specified text"
      placeholder="Search attendees..."
      leftSection={<IconSearch size={16} />}
      rightSection={
        <ActionIcon
          size="sm"
          variant="transparent"
          c="dimmed"
          onClick={() => setQuery(null)}
        >
          <IconX size={14} />
        </ActionIcon>
      }
      value={query?.[columnName] || ""}
      onChange={(e) => {
        if (e.currentTarget.value === "") {
          setQuery(null);
          return;
        }
        setQuery({ [columnName]: e.currentTarget.value });
      }}
    />
  );
}

function CategoryFilter({ query, setQuery }) {
  const { user } = useContext(AuthContext);
  const { eventId } = getCurrentWorkspace(user);

  const { data: userTags, isPending } = useGetPeopleTagsBySubtype({
    subtype: "categoryOfUser",
    eventId,
  });

  const tags = userTags?.map((tag) => ({
    value: String(tag.id),
    label: tag.title,
  }));

  if (isPending) {
    <Text>Loading tags...</Text>;
  }

  return (
    <Select
      label="Category"
      description="Filter attendees by category"
      placeholder="Filter by category"
      data={tags}
      value={query?.category}
      onChange={(value) => {
        setQuery({ category: value });
      }}
      onClear={() => setQuery(null)}
      clearable
    />
  );
}

export default AttendeesTable;
