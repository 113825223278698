import { ActionIcon, Group, Avatar } from "@mantine/core";
import { IconEdit } from "@tabler/icons-react";
import { DataTable } from "mantine-datatable";
import React from "react";
import { Link } from "react-router-dom";
import DeleteSpeakerModal from "./Delete";

function Table({ data, pagination, isPending, sort, setSort }) {
  return (
    <DataTable
      columns={[
        {
          accessor: "picture",
          render: (row) => (
            <Avatar
              src={row.picture}
              size="md"
              radius="sm"
              alt="avatar"
              color="initials"
              name={`${row.firstName} ${row.lastName}`}
            />
          ),
          align: "center",
          width: 75,
        },
        { accessor: "firstName", title: "First Name", sortable: true },
        { accessor: "lastName", title: "Last Name", sortable: true },
        { accessor: "occupation", title: "Occupation" },
        {
          accessor: "actions",
          width: 150,
          render: (row) => (
            <Group>
              <ActionIcon
                component={Link}
                to={`/app/speakers/${row.id}`}
                variant="light"
                color="blue"
              >
                <IconEdit />
              </ActionIcon>
              <DeleteSpeakerModal speakerId={row.id} />
            </Group>
          ),
        },
      ]}
      records={data?.data}
      fetching={isPending}
      withTableBorder
      striped
      withColumnBorders
      highlightOnHover
      page={pagination.data.page}
      onPageChange={(page) =>
        pagination.setPagination({ ...pagination.data, page })
      }
      sortStatus={sort}
      onSortStatusChange={setSort}
      recordsPerPage={data?.pagination.perPage}
      totalRecords={data?.pagination.total}
      minHeight={150}
    />
  );
}

export default Table;
