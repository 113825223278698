import React, { useContext } from "react";
import { AuthContext } from "../services/context";
import { getCurrentWorkspace } from "../services/userConfig";
import { useGetTicketHistory } from "../api/attendees";
import { DataTable } from "mantine-datatable";
import { Flex, Text, Group } from "@mantine/core";
import RoleBadges from "../components/RoleBadges";
import TicketHistoryDashboard from "../components/TicketHistoryDashboard";

function TicketHistory() {
  const { user } = useContext(AuthContext);
  const { eventId } = getCurrentWorkspace(user);

  const { data, isPending } = useGetTicketHistory({ eventId });

  if (isPending) {
    return <div>Loading...</div>;
  }
  return (
    <>
      <TicketHistoryDashboard eventId={eventId} />

      <DataTable
        columns={[
          {
            accessor: "attendee",
            title: "Attendee",
            render: (row) => row.firstName + " " + row.lastName,
          },
          {
            accessor: "change",
            title: "Changes",
            render: (row) =>
              row?.change?.map((change, idx) => (
                <Flex direction={"row"} key={idx}>
                  <Text fw="600" size="sm">
                    {change?.field}:{" "}
                  </Text>
                  <Text size="sm">
                    {String(change?.previousValue)} &rarr;{" "}
                    {String(change?.newValue)}
                  </Text>
                </Flex>
              )),
          },
          {
            accessor: "changedBy",
            title: "Changed by",
            render: (row) => (
              <Group>
                <Text>
                  {row?.changedBy?.firstName} {row?.changedBy?.lastName}
                </Text>
                <RoleBadges roleName={row?.changedBy?.role} />
              </Group>
            ),
          },
          { accessor: "updatedAt", title: "Updated" },
          {
            accessor: "status",
            title: "Status",
            render: (row) =>
              row?.changeStatus === "approved" ? (
                <Text size="sm" c="green">
                  Approved
                </Text>
              ) : (
                "Updated"
              ),
          },
        ]}
        rowBackgroundColor={({ change }) => {
          // Check if the change is an approval
          if (
            change.some((c) => c.field === "isApproved" && c.newValue === true)
          ) {
            return { dark: "#232b25", light: "#f0f7f1" };
          }
        }}
        records={data}
        withTableBorder
        withColumnBorders
        highlightOnHover
        minHeight={150}
        fetching={isPending}
      />
    </>
  );
}

export default TicketHistory;
