import { Loader, Text } from "@mantine/core";
import { useContext } from "react";
import { useParams } from "react-router-dom";

import { useGetSession } from "../../../api/session";
import { useGetSponsors } from "../../../api/sponsors";
import { getCurrentWorkspace } from "../../../services/userConfig";
import { AuthContext } from "../../../services/context";
import EditForm from "./Form";
import {
  useGetSessionTagsByEventId,
  useGetSessionTagsBySessionId,
} from "../../../api/tag";
import { useGetAllSpeakers } from "../../../api/speakers";

export default function EditSession() {
  const { id } = useParams();
  const { user } = useContext(AuthContext);

  const currentWorkspace = getCurrentWorkspace(user);

  const enableActions = user.role === "sysAdmin" || user.role === "wsAdmin";
  const { data: responseData, isPending, isError, error } = useGetSession(id);

  const { data: speakers } = useGetAllSpeakers({
    eventId: currentWorkspace.eventId,
    limit: 250,
  });

  const speakerOptions = speakers?.data?.map((speaker) => ({
    label: `${speaker.firstName} ${speaker.lastName}, ${speaker.occupation}`,
    value: speaker.id.toString(),
  }));

  const { data: sponsors } = useGetSponsors({
    workspaceId: currentWorkspace.id,
    eventId: currentWorkspace.eventId,
  });
  const { data: tagOptions } = useGetSessionTagsByEventId(
    currentWorkspace.eventId
  );
  const { data: sessionTags } = useGetSessionTagsBySessionId(id);

  if (isPending) {
    return <Loader mt={10} />;
  }

  if (isError) {
    return <Text c="red">{error.message}</Text>;
  }

  if (responseData && speakerOptions && sponsors && tagOptions && sessionTags) {
    const sponsorOptions = sponsors?.data.map((s) => ({
      label: s.name,
      value: s.id.toString(),
    }));

    return (
      <EditForm
        data={responseData}
        enableActions={enableActions}
        speakerOptions={speakerOptions}
        sponsorOptions={sponsorOptions}
        tagOptions={tagOptions}
        sessionTags={sessionTags}
      />
    );
  }
}
