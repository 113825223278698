import { ajvResolver } from "@hookform/resolvers/ajv";
import {
  Button,
  Container,
  ColorInput,
  FileInput,
  Flex,
  Image,
  Paper,
  Text,
} from "@mantine/core";
import { IconFileUpload } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { TextInput } from "react-hook-form-mantine";
import { modals } from "@mantine/modals";

import { usePatchEvent } from "../../../api/event";
import { useUpload } from "../../../api/r2";
import { toBase64 } from "../../../services/helper";
import CropImage from "./CropImage";
import { colorSwatches } from "./utils/colorUtils";

function CustomizeEvent({ data }) {
  const { config } = data;
  const { logoImage, headerImage, accentColor } = config || {};
  const name = data?.name;
  const [newLogoImage, setNewLogoImage] = useState(null);
  const [newHeaderImage, setNewHeaderImage] = useState(null);
  const [uploadStatus, setUploadStatus] = useState({
    logo: false,
    header: false,
  });
  const [color, setColor] = useState(accentColor ? accentColor : "#102694");

  const { mutate, isSuccess, isPending: isLoading } = usePatchEvent(data?.id);

  const {
    mutate: upload,
    isSuccess: uploadSuccess,
    isError: uploadIsError,
    error: uploadError,
  } = useUpload();

  const {
    control,
    handleSubmit,
    setValue,
    formState: { isDirty, dirtyFields },
    reset,
  } = useForm({
    defaultValues: {
      name,
      logoImage: logoImage,
      headerImage: headerImage,
      accentColor: color,
    },
    resolver: ajvResolver({
      type: "object",
      properties: {
        name: { type: "string", minLength: 1 },
      },
      required: ["name"],
    }),
  });

  useEffect(() => {
    reset({
      name,
      logoImage,
      headerImage,
      accentColor,
    });
  }, [reset, data]);

  const openImageModal = (img) => {
    modals.open({
      title: `${img.name}`,
      children: <Image src={img.src} style={{ maxWidth: 500 }} />,
    });
  };

  const openCropModal = (file) => {
    const imageSrc = URL.createObjectURL(file);

    modals.open({
      title: "Crop image",
      children: (
        <CropImage
          imageSrc={imageSrc}
          imageName={file.name}
          setNewHeaderImage={setNewHeaderImage}
        />
      ),
    });
    // Clean up the URL object after usage
    return () => URL.revokeObjectURL(imageSrc);
  };

  const onSubmit = (data) => {
    data.config = { ...config };
    if (dirtyFields.logoImage) {
      data.config.logoImage = `https://pub-45c81e40f4c74a34bacddebce6171d1d.r2.dev/${newLogoImage.name}`;
    }

    if (dirtyFields.headerImage) {
      data.config.headerImage = `https://pub-45c81e40f4c74a34bacddebce6171d1d.r2.dev/${newHeaderImage.name}`;
    }

    if (dirtyFields.accentColor) {
      data.config.accentColor = color;
    }
    mutate({ name: data.name, config: data.config });
  };

  const handleUpload = async (field, file) => {
    try {
      const data = await toBase64(file);
      upload({ name: file.name, data });

      setUploadStatus((prev) => ({ ...prev, [field]: true }));
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  return (
    <Container size={500}>
      <Paper withBorder shadow="md" p={16} mt={16} radius="md">
        <form onSubmit={handleSubmit(onSubmit)}>
          <TextInput
            label="Event title"
            type="text"
            name="name"
            defaultValue={name}
            control={control}
            withAsterisk
            mt="md"
          />

          <Flex
            justify="space-around"
            my={5}
            style={{ justifyContent: "center", gap: 15 }}
          >
            {logoImage && (
              <Image
                src={logoImage}
                style={{ maxWidth: 100, cursor: "zoom-in" }}
                onClick={() =>
                  openImageModal({ name: "Logo image", src: logoImage })
                }
              />
            )}
            <FileInput
              label="Logo image"
              name="logoImage"
              description="Logo that is shown on the homepage of the application."
              placeholder="Upload file"
              clearable
              accept="image/png,image/jpeg"
              leftSection={<IconFileUpload />}
              style={{ width: "50%" }}
              onChange={(value) => {
                setNewLogoImage(value);
                setValue("logoImage", newLogoImage, {
                  shouldDirty: true,
                });
              }}
            />
            <Button
              style={{ alignSelf: "end" }}
              disabled={!dirtyFields.logoImage || uploadStatus.logo}
              onClick={() => handleUpload("logo", newLogoImage)}
            >
              Upload
            </Button>
          </Flex>

          <Flex
            justify="space-around"
            my={5}
            style={{ justifyContent: "center", gap: 15 }}
          >
            {headerImage && (
              <Image
                src={headerImage}
                style={{ maxWidth: 100, cursor: "zoom-in" }}
                onClick={() =>
                  openImageModal({ name: "Header image", src: headerImage })
                }
              />
            )}

            <FileInput
              label="Header image"
              name="headerImage"
              description="Image shown at the top of the mobile home page."
              placeholder="Upload file"
              clearable
              accept="image/png,image/jpeg"
              leftSection={<IconFileUpload />}
              style={{ width: "50%" }}
              onChange={(value) => {
                if (value) openCropModal(value);
                setValue("headerImage", newHeaderImage, {
                  shouldDirty: true,
                });
              }}
            />

            <Button
              style={{ alignSelf: "end" }}
              disabled={!newHeaderImage || uploadStatus.header}
              onClick={async () => {
                handleUpload("header", newHeaderImage);
              }}
            >
              Upload
            </Button>
          </Flex>

          <ColorInput
            label="Accent color"
            name="accentColor"
            control={control}
            value={color}
            my={10}
            withEyeDropper={false}
            withPicker={false}
            swatches={colorSwatches}
            swatchesPerRow={10}
            closeOnColorSwatchClick
            onChange={(value) => {
              setColor(value);
              setValue("accentColor", value, { shouldDirty: true });
            }}
          />

          {uploadSuccess && (
            <Text size="sm" c="green" mt="sm">
              Upload successful. Please save event config changes.
            </Text>
          )}

          <Button
            type="submit"
            mt="md"
            loading={isLoading}
            disabled={!isDirty || isSuccess}
          >
            Save event config
          </Button>
        </form>

        {uploadIsError && (
          <Text size="md" c="red" mt="md">
            {uploadError.message}
          </Text>
        )}
      </Paper>
    </Container>
  );
}

export default CustomizeEvent;
