import { useContext, useState } from "react";
import { AuthContext } from "../services/context";
import { useGetAllAnnouncements } from "../api/announcements";
import { getCurrentWorkspace } from "../services/userConfig";
import { Container, Text, Title } from "@mantine/core";
import AnnouncementsTable from "../components/Announcements/AnnouncementsTable";

function SpecialInvitation() {
  const { user } = useContext(AuthContext);
  const { eventId } = getCurrentWorkspace(user);

  const [pagination, setPagination] = useState({ page: 1, limit: 10 });

  const { data, isSuccess } = useGetAllAnnouncements({
    type: "special",
    getAll: true,
    eventId,
    ...pagination,
  });

  return (
    <Container>
      <Title>Special Invitation</Title>
      <Text size="sm">
        Special invitations are the announcements that are targeted to a
        specific group of users (that have a specific tag).
        <br />
        These announcements are used to invite users to special events. For
        example, you can create a special invitation for the dinner event and
        target it to the users that have the VIP tag.
        <br />
        <br />
        The special invitations are displayed in the home page of the
        application as a button with the title of the Special Invitation. When
        the user clicks on the button, the invitation is displayed as an image
        that is uploaded.
      </Text>
      {isSuccess && (
        <AnnouncementsTable
          data={data}
          pagination={{ data: pagination, setPagination }}
          hideTextField
        />
      )}
    </Container>
  );
}

export default SpecialInvitation;
