import { useContext, useState } from "react";
import { AuthContext } from "../services/context";
import { getCurrentWorkspace } from "../services/userConfig";
import { useGetAllSpeakers } from "../api/speakers";

import Table from "../components/Speakers/Table";

function Speakers() {
  const { user } = useContext(AuthContext);
  const currentWorkspace = getCurrentWorkspace(user);
  const currentEventId = currentWorkspace.eventId;

  const [pagination, setPagination] = useState({ page: 1, limit: 10 });

  const [sort, setSort] = useState({
    columnAccessor: "firstName",
    direction: "asc",
  });

  const { data, isPending } = useGetAllSpeakers({
    eventId: currentEventId,
    ...pagination,
    sort,
  });

  return (
    <Table
      data={data}
      isPending={isPending}
      pagination={{ data: pagination, setPagination }}
      sort={sort}
      setSort={setSort}
    />
  );
}

export default Speakers;
