import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";

import "@mantine/core/styles.css";
import "@mantine/notifications/styles.css";
import "@mantine/dropzone/styles.css";
import "mantine-datatable/styles.css";
import "@mantine/tiptap/styles.css";
import "./App.css";
import AnonRoute from "./components/AnonRoute";
import Login from "./components/Auth/Login";
import Register from "./components/Auth/Register";
import EditProfile from "./components/EditProfile";
import CreateEvent from "./components/Events/CreateEvent";
import CustomizeEvent from "./components/Events/Customize";
import EditEvent from "./components/Events/Edit";
import GuardRoute from "./components/GuardRoute";
import CreatePolls from "./components/Polls/CreatePolls";
import EditPolls from "./components/Polls/EditPolls";
import PollResults from "./components/Polls/PollResult";
import Profile from "./components/Profile";
import CreateSession from "./components/Sessions/CreateSession";
import EditSession from "./components/Sessions/Edit";
import CreateSponsor from "./components/Sponsors/CreateSponsor";
import EditSponsor from "./components/Sponsors/Edit";
import CreateUser from "./components/Users/CreateUser";
import EditUser from "./components/Users/EditUser";
import CreateWorkSpace from "./components/Workspaces/CreateWorkSpace";
import EditWorkspace from "./components/Workspaces/Edit";
import AppShell from "./pages/AppShell";
import Callback from "./pages/Callback";
import Dashboard from "./pages/Dashboard";
import Events from "./pages/Events";
import Home from "./pages/Home";
import NotFound from "./pages/NotFound";
import Polls from "./pages/Polls";
import PollsSessions from "./pages/PollsSessions";
import Sessions from "./pages/Sessions";
import Sponsors from "./pages/Sponsors";
import Users from "./pages/Users";
import Workspaces from "./pages/Workspaces";
import Pages from "./pages/Pages";
import CreatePage from "./components/Pages/CreatePage";
import EditPage from "./components/Pages/EditPage";
import Announcements from "./pages/Announcements";
import CreateAnnouncement from "./components/Announcements/CreateAnnouncement";
import EditAnnouncement from "./components/Announcements/EditAnnouncement";
import ResetPassword from "./components/Auth/ResetPassword";
import GoogleSignIn from "./components/Auth/GoogleSignIn";
import Tags from "./pages/Tags";
import CreateTag from "./components/Tags/CreateTag";
import ColorTag from "./components/Tags/ColorTags";
import EditTag from "./components/Tags/Edit";
import Attendees from "./pages/Attendees";
import CreateAttendee from "./components/Attendees/Create";
import EditAttendee from "./components/Attendees/Edit";
import ImportAttendees from "./components/Attendees/ImportAttendees";
import SysAdminRoute from "./components/SysAdminRoute";
import SpecialInvitation from "./pages/SpecialInvitation";
import CreateSpecialInvite from "./components/SpecialInvite/Create";
import Speakers from "./pages/Speakers";
import Create from "./components/Speakers/Create";
import EditSpeaker from "./components/Speakers/Edit";
import ImportSpeakers from "./components/Speakers/Import";
import TicketHistory from "./pages/TicketHistory";

const App = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/" element={<AnonRoute />}>
        <Route path="login" element={<Login />} />
        <Route path="invite/:token" element={<Register />} />
        <Route path="forgot-password/:token" element={<ResetPassword />} />
        <Route path="google-sign-in" element={<GoogleSignIn />} />
      </Route>
      <Route element={<GuardRoute />}>
        <Route
          path="/app/polls/:id/results/present"
          element={<PollResults />}
        />
        <Route
          path="app"
          element={<AppShell />}
          handle={{
            name: "Dashboard",
          }}
        >
          <Route index element={<Home />} />
          <Route
            path="users"
            handle={{
              name: "Users",
            }}
            element={<SysAdminRoute />}
          >
            <Route
              index
              element={<Users />}
              handle={{
                action: "create",
              }}
            />
            <Route path="create" element={<CreateUser />} />
            <Route path=":id" element={<EditUser />} />
          </Route>
          <Route
            path="events"
            handle={{
              name: "Events",
            }}
          >
            <Route
              index
              element={<Events />}
              handle={{
                action: "create",
              }}
            />
            <Route path="create" element={<CreateEvent />} />
            <Route path=":id" element={<EditEvent />} />
            <Route path="customize" element={<CustomizeEvent />} />
          </Route>
          <Route path="profile">
            <Route index element={<Profile />} />
            <Route path="edit" element={<EditProfile />} />
          </Route>
          <Route
            path="dashboard"
            element={<Dashboard />}
            handle={{
              name: "Dashboard",
            }}
          />
          <Route path="workspaces" handle={{ name: "Workspaces" }}>
            <Route
              index
              element={<Workspaces />}
              handle={{
                action: "create",
              }}
            />
            <Route path="create" element={<CreateWorkSpace />} />
            <Route path=":id" element={<EditWorkspace />} />
          </Route>
          <Route
            path="sessions"
            handle={{
              name: "Sessions",
            }}
          >
            <Route
              index
              element={<Sessions />}
              handle={{
                action: "create",
              }}
            />
            <Route path="create" element={<CreateSession />} />
            <Route path=":id" element={<EditSession />} />
          </Route>
          <Route path="/app/polls" handle={{ name: "Polls" }}>
            <Route
              index
              element={<PollsSessions />}
              handle={{
                action: "create",
              }}
            />
            <Route path="create" element={<CreatePolls />} />
            <Route
              path="/app/polls/:sessionId"
              element={<Polls />}
              handle={{
                action: "create",
              }}
            />
            <Route path="/app/polls/:id/edit" element={<EditPolls />} />
            <Route path="/app/polls/:id/results" element={<PollResults />} />
          </Route>
          <Route
            path="sponsors"
            handle={{
              name: "Sponsors",
            }}
          >
            <Route index element={<Sponsors />} handle={{ action: "create" }} />
            <Route path="create" element={<CreateSponsor />} />
            <Route path=":id" element={<EditSponsor />} />
          </Route>
          <Route
            path="pages"
            handle={{
              name: "Pages",
            }}
          >
            <Route index element={<Pages />} handle={{ action: "create" }} />
            <Route path="create" element={<CreatePage />} />
            <Route path=":id" element={<EditPage />} />
          </Route>
          <Route
            path="announcements"
            handle={{
              name: "Announcements",
            }}
          >
            <Route
              index
              element={<Announcements />}
              handle={{ action: "create" }}
            />
            <Route path="create" element={<CreateAnnouncement />} />
            <Route path=":announcementId" element={<EditAnnouncement />} />
          </Route>
          <Route
            path="special-invitation"
            handle={{
              name: "Special Invitation",
            }}
          >
            <Route
              index
              element={<SpecialInvitation />}
              handle={{ action: "create" }}
            />
            <Route path="create" element={<CreateSpecialInvite />} />
          </Route>
          <Route
            path="tags"
            handle={{
              name: "Tags",
            }}
          >
            <Route
              index
              element={<Tags />}
              handle={{ action: ["colorTags", "create"] }}
            />
            <Route path="create" element={<CreateTag />} />
            <Route path="colorTags" element={<ColorTag />} />
            <Route path=":tagId" element={<EditTag />} />
          </Route>
          <Route path="attendees" handle={{ name: "Attendees" }}>
            <Route
              index
              element={<Attendees />}
              handle={{
                action: ["bulkEmail", "import", "create"],
              }}
            />
            <Route path="create" element={<CreateAttendee />} />
            <Route path=":attendeeId" element={<EditAttendee />} />
            <Route path="import" element={<ImportAttendees />} />
          </Route>
          <Route path="tickets" handle={{ name: "Ticket History" }}>
            <Route index element={<TicketHistory />} />
          </Route>

          <Route path="speakers" handle={{ name: "Speakers" }}>
            <Route
              index
              element={<Speakers />}
              handle={{
                action: ["import", "create"],
              }}
            />
            <Route path="create" element={<Create />} />
            <Route path=":speakerId" element={<EditSpeaker />} />
            <Route path="import" element={<ImportSpeakers />} />
          </Route>
        </Route>
      </Route>
      <Route path="*" element={<NotFound />} />
      <Route path="/callback" element={<Callback />} />
    </>
  )
);

export default App;
